<template>
  <div class="page_section d-flex justify-content-center align-items-center">
    <div class="error_page">
      <h1>404</h1>
      <br />
      <h6>SEITE NICHT GEFUNDEN</h6>
      <p>
        Entschuldigung, hier stimmt etwas nicht. Bitte versuchen Sie es über eine andere Seite.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
</style>
